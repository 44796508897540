import * as React from 'react'
import { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'

import Seo from '../../components/seo'
import Layout from '../../components/layout'
import Hero from '../../components/hero'

const NUMS = 37
const BALLS = [...Array(NUMS)].map((_, i) => i + 1)

const Page = (props) => {
  const [sheets, setSheets] = useState([
    {
      key: Math.random(),
      selectNums: [],
      lockedNums: [],
    },
    {
      key: Math.random(),
      selectNums: [],
      lockedNums: [],
    },
    {
      key: Math.random(),
      selectNums: [],
      lockedNums: [],
    },
    {
      key: Math.random(),
      selectNums: [],
      lockedNums: [],
    },
    {
      key: Math.random(),
      selectNums: [],
      lockedNums: [],
    },
  ])
  const updateSheet = (index, data) => {
    const tmpSheets = [...sheets]
    tmpSheets[index] = data
    setSheets(tmpSheets)
  }
  const deleteSheet = (index) => {
    if (sheets.length === 1) return
    setSheets(sheets.filter((_, i) => index !== i))
  }
  return (
    <Layout location={props.location}>
      <Seo title="Loto7" />
      <Hero title="Loto7" />
      <div className="bg-white">
        <div className="max-w-screen-lg px-4 mx-auto">
          <div className="grid gap-1">
            {sheets.map((e, index) => {
              return (
                <Sheet
                  key={e.key}
                  updateKey={index}
                  updateSheet={updateSheet}
                  deleteSheet={deleteSheet}
                  lockedNums={e.lockedNums}
                  selectNums={e.selectNums}
                />
              )
            })}
            <div className="bg-gray-50 border rounded-lg shadow-sm flex justify-start">
              <div className="grid gap-1 p-8">
                <button
                  onClick={() => {
                    setSheets([
                      ...sheets,
                      {
                        selectNums: [],
                        lockedNums: [],
                        key: Math.random(),
                      },
                    ])
                  }}
                  className="bg-emerald-300 hover:opacity-50 rounded px-4 py-2"
                >
                  シート追加
                </button>
              </div>
            </div>
            <Summary sheets={sheets} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Summary = (props) => {
  return (
    <div className="bg-gray-50 border rounded-lg shadow-sm flex justify-start sticky bottom-0">
      <div className="grid grid-cols-7 gap-1 p-8">
        {BALLS.map((e) => (
          <div
            key={e}
            className={`border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer ${(() => {
              const cnt = props.sheets.filter((sheet) => {
                return [...sheet.selectNums, ...sheet.lockedNums].includes(e)
              }).length
              if (cnt === 0) {
                return 'bg-white'
              } else if (cnt > 9) {
                return 'bg-red-900'
              }
              return `bg-red-${cnt}00`
            })()}`}
          >
            <div className="my-auto">{e}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const Sheet = (props) => {
  const [selectNums, setSelectNums] = useState(props.selectNums)
  const [lockedNums, setLockedNums] = useState(props.lockedNums)
  const [result, setResult] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
  })

  useEffect(() => {
    checkAllAnswer()
  }, [selectNums])

  const updateParent = (data) => {
    props.updateSheet(props.updateKey, data)
  }

  const clickNumber = (num) => {
    if (lockedNums.includes(num)) {
      const updatedLockedNums = lockedNums.filter((e) => e !== num)
      setLockedNums(updatedLockedNums)
      updateParent({
        selectNums,
        lockedNums: updatedLockedNums,
        key: Math.random(),
      })
    } else if (selectNums.includes(num)) {
      const updatedSelectNums = selectNums.filter((e) => e !== num)
      const updatedLockedNums = [...lockedNums, num]
      setSelectNums(updatedSelectNums)
      setLockedNums(updatedLockedNums)
      updateParent({
        selectNums: updatedSelectNums,
        lockedNums: updatedLockedNums,
        key: Math.random(),
      })
    } else if (selectNums.length + lockedNums.length < 7) {
      const updatedSelectNums = [...selectNums, num]
      setSelectNums(updatedSelectNums)
      updateParent({
        selectNums: updatedSelectNums,
        lockedNums,
        key: Math.random(),
      })
    }
  }

  const clearSelectNum = () => {
    setSelectNums([])
    updateParent({
      selectNums: [],
      lockedNums,
      key: Math.random(),
    })
  }

  const randomNumber = () => {
    const addNums = []
    while (lockedNums.length + addNums.length < 7) {
      const samples = BALLS.filter(
        (e) => ![...lockedNums, ...addNums].includes(e)
      )
      addNums.push(samples[Math.floor(Math.random() * samples.length)])
    }
    setSelectNums(addNums)
    updateParent({
      selectNums: addNums,
      lockedNums,
      key: Math.random(),
    })
  }

  const getMatchCount = (checkNums, answers) => {
    return checkNums.filter((checkNum) => answers.includes(checkNum)).length
  }

  const checkAllAnswer = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const answers = require('./loto7.json')
    const result = answers.reduce(
      (acc, answer) => {
        if (checkAnswer(answer)) {
          acc[checkAnswer(answer)]++
          return acc
        }
      },
      {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
      }
    )
    setResult(result)
  }

  const checkAnswer = (answer) => {
    const checkNums = [...selectNums, ...lockedNums].sort()
    if (getMatchCount(checkNums, answer.mainNums) === 7) {
      return '1'
    }

    if (
      getMatchCount(checkNums, answer.mainNums) === 6 &&
      getMatchCount(checkNums, answer.subNums) === 1
    ) {
      return '2'
    }

    if (getMatchCount(checkNums, answer.mainNums) === 6) {
      return '3'
    }

    if (getMatchCount(checkNums, answer.mainNums) === 5) {
      return '4'
    }

    if (getMatchCount(checkNums, answer.mainNums) === 4) {
      return '5'
    }

    if (
      getMatchCount(checkNums, answer.mainNums) === 3 &&
      [1, 2].includes(getMatchCount(checkNums, answer.subNums))
    ) {
      return '6'
    }

    return '0'
  }

  return (
    <div className="bg-gray-50 border rounded-lg shadow-sm flex justify-start">
      <div className="grid grid-cols-7 gap-1 p-4">
        {BALLS.map((e) => (
          <div
            key={e}
            onClick={() => {
              clickNumber(e)
            }}
            className={`border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer ${
              selectNums.includes(e)
                ? 'bg-emerald-300'
                : lockedNums.includes(e)
                ? 'bg-red-300'
                : 'bg-white'
            }`}
          >
            <div className="my-auto">{e}</div>
          </div>
        ))}
        <div className="border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer"></div>
        <div className="border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer"></div>
        <div
          onClick={clearSelectNum}
          className="border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer"
        >
          <div className="my-auto">
            <i className="bi-eraser"></i>
          </div>
        </div>
        <div
          onClick={randomNumber}
          className="border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer"
        >
          <div className="my-auto">
            <i className="bi-dice-3"></i>
          </div>
        </div>
        <div
          onClick={() => {
            props.deleteSheet(props.updateKey)
          }}
          className="border h-10 w-10 align-middle rounded-md flex justify-items-center justify-center hover:opacity-50 cursor-pointer"
        >
          <div className="my-auto">
            <i className="bi-trash"></i>
          </div>
        </div>
        <div className="col-span-7 border rounded-md bg-white">
          <div className="px-2">歴代当選回数</div>
          <div className="flex justify-items-center justify-around">
            {[...Array(6)].map((_, index) => (
              <div key={index}>
                <i className={`bi-dice-${index + 1} mr-1`}></i>
                {result[String(index + 1)]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page
